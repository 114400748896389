export default function () {

	return [
		{
			title: 'CV database',
			icon: 'ArchiveIcon',
			route: {
				name: 'cvs',
			},
			module: 'cv',
		},
		{
			title: 'Job offers',
			route: {
				name: 'job-offers',
			},
			icon: 'BriefcaseIcon',
			module: 'job-offers',
		},
		{
			title: 'Applications',
			icon: 'UsersIcon',
			route: {
				name: 'applications',
				params: {stage: 'ALL'}
			},
			module: 'applications',
		},

	];
}
