<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
    >
        <template #button-content>
            <div class="d-sm-flex user-nav bv-d-xs-down-none">
                <p class="user-name font-weight-bolder mb-0">
                    {{ i18nT(`Hello`) }}, {{ userData.FirstName }}
                </p>
            </div>
            <b-avatar
                size="40"
                :src="avatarUrl"
                :key="avatarUrl"
                variant="light-primary"
                badge
                class="badge-minimal"
                badge-variant="success"
            >
            </b-avatar>

        </template>

        <b-dropdown-item
            :to="{ name: 'pages-profile' }"
            link-class="d-flex align-items-center"

        >
            <feather-icon size="16" icon="UserIcon" class="mr-50"/>
            <span>{{ i18nT(`Profile`) }}</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
            <span>{{ i18nT(`Logout`) }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
} from 'bootstrap-vue';

import useAuth from '@/auth/useAuth';
import {avatarText} from '@core/utils/filter';
import $store from '@/store';
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        // BDropdownDivider,
        BAvatar,
    },
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            avatarText,
            userType: useAuth.getUserType(),
            avatarUrl: '',
            showAvatar: true,
        };
    },
    mounted() {
        this.$store.commit('app/UPDATE_LOCAL_USER', this.userData);
    },
    computed: {
        rawUserData() {
           return $store.state.app.localUserData;
        },
    },
    watch: {
        rawUserData : {
            deep: true,
            handler: function (newVal) {
                if(newVal) {
                    let self = this;
                    console.log('ImageUrl: ', newVal.ImageUrl)
                    this.userData = newVal
                    this.showAvatar = false;
                    this.avatarUrl = newVal.ImageUrl;
                    setTimeout(function () {
                        self.showAvatar = true;
                    }, 100)
                }
            },

        },
    },
    methods: {
        logout() {
            //Stop message pinger
            clearInterval(this.$store.state.app.msgRefreshIntervalId);

            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useAuth.authConfig.storageTokenKeyName);
            localStorage.removeItem(useAuth.authConfig.storageUserTypeKeyName);
            localStorage.removeItem(useAuth.authConfig.storageRefreshTokenKeyName);
            localStorage.removeItem(useAuth.authConfig.storageCurWorkspaceKeyName);
            localStorage.removeItem(useAuth.authConfig.storageWorkspacesKeyName);
            localStorage.removeItem(useAuth.authConfig.storageProfileKeyName);
            localStorage.removeItem(useAuth.authConfig.storageSoftwaresKeyName);
            localStorage.removeItem(useAuth.authConfig.storageCurrentSoftwareKeyName);

            // Remove userData from localStorage
            localStorage.removeItem('userData');

            //Clear also all state data
            useAuth.resetUser()

            // Redirect to login page
            this.$router.push({name: 'auth-login'});

        },
    },
};
</script>
