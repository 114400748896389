/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard';
import appsAndPages from './apps-and-pages';
import others from './others';
import chartsAndMaps from './charts-and-maps';
import uiElements from './ui-elements';
import formAndTable from './forms-and-table';
import useAuth from '@/auth/useAuth';
import common from './common';
import accounting from './accounting';
import recruiting from './recruiting';
import onboarding from './onboarding';
import engagement from './engagement';
import hr from './hr';
import candidate from './candidate';
import employee from './employee';

// Array of sections
export default async function() {
  const software = useAuth.getCurrentSoftware();
  const userType = useAuth.getUserType();

  let curWorkspace = useAuth.getCurWorkspace();
  //debugger;

  let navs = userType === 'Freelancer' ? [] : [...common()];
  let softwareNavs = [];

  switch (software) {
    case 're':
      softwareNavs = await recruiting();
      break;
    case 'on':
      softwareNavs = await onboarding();
      break;
    case 'en':
      softwareNavs = await engagement();
      break;
    case 'hr':
      softwareNavs = await hr();
      break;
    case 'ac':
      navs = []
      softwareNavs = await accounting();
      break;
    case 'ca':
      softwareNavs = await candidate();
      break;
    case 'em':
      softwareNavs = await employee();
      break;
  }

  if (softwareNavs) {
    navs = softwareNavs.concat([...navs]);
  }


  if (process.env.SKILEO_DEV_MODE === '1') {
    let debugNavs = [
      ...dashboard,
      ...appsAndPages,
      ...uiElements,
      ...formAndTable,
      ...chartsAndMaps,
      ...others,
    ];
    navs = navs.concat(debugNavs);
  }

  //Add dashboard if you have a current workspace and you're not in accounting
  if (software !== 'ac' && curWorkspace && curWorkspace.Id)
    navs.unshift({
      title: 'Dashboard',
      route: {
        path: '/'+software+'/dashboard',
      },
      icon: 'MonitorIcon',
      module: 'always',
    },)

  //console.log("Menu navs before filter: ", navs);
  let filteredNav = [];

  if(softwareNavs.length > 0) {

    navs.forEach((nav, index) => {
      // console.log("===== =====");
      // console.log("Nav entry   : ", nav.title);
      // console.log("Perm. module: ", nav.module);
      // console.log("Can see it  : ", useAuth.iCan(nav.module));
      // console.log("===== =====");
      if(useAuth.iCan(nav.module)){
        filteredNav.push(nav);
      }
    });

  }

  return filteredNav;
}
// export default [...navigation];
