import useAuth from '@/auth/useAuth';
export default async function() {
    const software = useAuth.getCurrentSoftware();
    return [{
            title: 'Employees',
            icon: 'UsersIcon',
            children: [{
                    title: 'Employees',
                    route: {
                        name: 'employees',
                        params: { software },
                    },
                },
                {
                    title: 'Teams',
                    route: {
                        name: 'employee-groups',
                        params: { software },
                    },
                },
            ],
            module: 'employees',
        },
        {
            title: 'Time off',
            icon: 'BellOffIcon',
            route: { name: 'hr-leave-absence' },
            module: 'leave_absence',
        },
        {
            title: 'Timesheets',
            icon: 'ClockIcon',
            route: { name: 'hr-timesheets' },
            module: 'timesheets',
        },
    ];
}
