import useAuth from '@/auth/useAuth';

export default async function () {
	const software = useAuth.getCurrentSoftware();
	return [
		{
			title: 'Employees',
			icon: 'UsersIcon',
			children: [
				{
					title: 'Employees',
					route: {
						name: 'employees',
						params: {software},
					},
				},
				{
					title: 'Teams',
					route: {
						name: 'employee-groups',
						params: {software},
					},
				},
			],
		},
		{
			title: 'Surveys',
			icon: 'ClipboardIcon',
			children: [
				{
					title: 'Surveys',
					route: {name: 'surveys'},
				},
				{
					title: 'Survey templates',
					route: {name: 'survey-templates'},
				},
				{
					title: 'Question templates',
					route: {name: 'survey-questions'},
				},
			],
			module: 'surveys',
		},
		{
			title: 'Suggestions',
			icon: 'InboxIcon',
			route: {
				name: 'suggestions',
			},
			module: 'suggestions',
		},
	];
}
