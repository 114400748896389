<template>
  <div
    class="app-content content"
    :class="[$route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>

      <!-- Suggestion box -->
      <div class="row">
        <div class="col-md-12">
          <div class="card suggestion-box">
            <div class="card-body">
              <h4 class="mb-2">
                <feather-icon
                  icon="FeatherIcon"
                  size="22"
                />
                {{i18nT(`How can we make things better for you?`)}}
              </h4>
              <div
                role="group"
                class="form-group"
              >
                <label
                  for="suggestionbox"
                  class="d-block"
                >{{i18nT(`Share your ideas`)}}</label>
                <div>
                  <textarea
                    id="suggestionbox"
                    name="suggestionbox"
                    rows="3"
                    wrap="soft"
                    class="form-control"
                    v-model="suggestionText"
                  >
                    </textarea>
                </div>
              </div>
              <div class="row">
                <div style="flex: 1 1 0%;">
                </div>
                <button
                  type="button"
                  class="btn mt-0 md-2 ml-1 mr-1 btn-outline-secondary"
                  :disabled="!suggestionText"
                  @click="sendSuggestion"
                >
                  {{i18nT(`Send`)}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition,
      contentWidth
    }
  },
  data() {
    return {
      suggestionText: ''
    }
  },
  methods: {
    sendSuggestion() {
      let formData = new FormData()
      let self = this
      formData.append('text', this.suggestionText)
      formData.append('currentPageUrl', this.$route.path)
      this.$http
        .post(`system/userSuggestion`, formData)
        .then(resp => {
          this.$swal({
            icon: 'success',
            title: resp.data.message,
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            confirmButtonText: this.i18nT(`Close`)
          })
          self.suggestionText = ''
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.errorMessage(err),
              icon: 'InfoIcon',
              variant: 'danger'
            }
          })
          self.suggestionText = ''
        })
    }
  }
}
</script>

<style>
</style>
