import useAuth from '@/auth/useAuth';

const software = useAuth.getCurrentSoftware();

export default async function () {
	return [
		{
			title: 'Time off',
			icon: 'BellOffIcon',
			route: {name: 'em-leave-absence'},
			module: 'leave_absence',
		},
		{
			title: 'Timesheets',
			icon: 'ClockIcon',
			route: {name: 'em-timesheets'},
			module: 'timesheets',
		},
		{
			title: 'Surveys',
			icon: 'ClipboardIcon',
			route: {name: 'em-surveys'},
			module: 'surveys',
		},
		{
			title: 'Suggestions',
			icon: 'InboxIcon',
			route: {
				name: 'em-suggestions',
			},
			module: 'suggestions',
		},
		{
			title: 'Tasks',
			icon: 'CheckCircleIcon',
			route: {
				name: 'tasks',
				params: {software: 'em'},
			},
			module: 'tasks',
		},
		{
			title: 'Documents',
			icon: 'FolderIcon',
			route: {
				name: 'documents',
				params: {software: 'em'},
			},
			module: 'documents',
		},
	];
}
