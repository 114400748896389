<template>
    <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
    >
        <template #button-content>
            <feather-icon
                :badge="allNotifications.length"
                badge-classes="bg-danger"
                class="text-body"
                icon="BellIcon"
                size="21"
            />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                    {{ i18nT(`Messages`) }}
                </h4>
                <b-badge
                    pill
                    variant="light-primary"
                >
                    {{allNotifications.length}} New
                </b-badge>
            </div>
        </li>

        <!-- Notifications -->
        <vue-perfect-scrollbar
            v-once
            :settings="perfectScrollbarSettings"
            class="scrollable-container media-list scroll-area"
            tagname="li"
        >
            <!-- Account Notification -->
            <b-link
                v-for="notification in allNotifications"
                :key="notification.subtitle"
                @click="reload"
            >
                <b-media>
                    <template #aside>
                        <!-- <b-avatar
                            size="32"
                            :src="notification.avatar"
                            :text="notification.avatar"
                            :variant="notification.type"
                        /> -->

                        <b-avatar
                            size="32"
                            :variant="notification.type"
                        >
                            <feather-icon :icon="notification.icon"/>
                        </b-avatar>
                    </template>
                    <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
                    </p>
                    <small class="notification-text" v-html="notification.subtitle"></small>
                </b-media>
            </b-link>

            <!-- System Notification Toggler -->
            <div class="media d-flex align-items-center">
                <h6 class="font-weight-bolder mr-auto mb-0">
                    System Notifications
                </h6>
                <b-form-checkbox
                    :checked="true"
                    switch
                />
            </div>

            <!-- System Notifications -->
            <b-link
                v-for="notification in systemNotifications"
                :key="notification.subtitle"
                >
                <b-media>
                    <template #aside>
                        <b-avatar
                            size="32"
                            :variant="notification.type"
                        >
                            <feather-icon :icon="notification.icon"/>
                        </b-avatar>
                    </template>
                    <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
                    </p>
                    <small class="notification-text">{{ notification.subtitle }}</small>
                </b-media>
            </b-link>
        </vue-perfect-scrollbar>

        <!-- Cart Footer -->
        <li class="dropdown-menu-footer">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
            >Read all notifications
            </b-button>
        </li>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from "@/store";
import emailStoreModule from "@/views/apps/email/emailStoreModule";
import {onUnmounted} from "@vue/composition-api";
import useAuth from "@/auth/useAuth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BAvatar,
        VuePerfectScrollbar,
        BButton,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },

    data() {
        return {
            allNotifications: [
            {
                title: 'Congrats, you are hired',
                subtitle: 'Click to vist your new workspace :)',
                type: 'light-success',
                icon: 'CheckIcon',
            },{
                title: 'Congrats, you are hired',
                subtitle: 'Click to vist your new workspace :)',
                type: 'light-success',
                icon: 'CheckIcon',
            },
            {
                title: 'Congrats, you are hired',
                subtitle: 'Click to vist your new workspace :)',
                type: 'light-success',
                icon: 'CheckIcon',
            }
            ],
        }
    },
    // computed: {
    //     notifications() {
    //         return store.getters['emailStoreModule/notifications']
    //     },
    //     systemNotifications() {
    //         return store.getters['emailStoreModule/systemNotifications']
    //     },
    //     perfectScrollbarSettings() {
    //         return {
    //             wheelPropagation: false,
    //         }
    //     },
    // },
    methods: {
        reload() {
            this.$http.post(`users/publicLogin`)
            .then(({data}) => {
                console.log(data)
                if(data.data.user_type === "Freelancer" && data.data.token) {
                    useAuth.setToken(data.data.token)
                    useAuth.setCurWorkspace(data.data.current_workspace)
                    useAuth.setWorkspaces(data.data.menu.workspaces)
                    useAuth.setSoftwares(data.data.menu.apps)
                    useAuth.setCurrentSoftware(data.data.current_sotftware)
                    useAuth.setProfile(data.data.menu.user)
                    useAuth.setUserType(data.data.user_type)
                    useAuth.setUserPermissions(data.data.menu.user.Permissions)

                    localStorage.setItem(
                        'userData',
                        JSON.stringify(data.data.menu.user)
                    )
                    store.commit('app/UPDATE_LOCAL_USER', data.data.menu.user)

                    this.$emit('authenticated', data);

                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `${data.data.menu.user.FirstName} ${data.data.menu.user.LastName},`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: this.i18nT(`Welcome to the family. Wohooo!!`)
                        }
                    })
                    this.$router.go()
                }
            })
        }
    },
    setup() {
        /* eslint-disable global-require */
        let notifications = []
        /* eslint-disable global-require */

        const EMAIL_APP_STORE_MODULE_NAME = 'app-email'
        // Register module
        if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
        })

        const systemNotifications = [
            {
                title: 'Server down',
                subtitle: 'USA Server is down due to hight CPU usage',
                type: 'light-danger',
                icon: 'XIcon',
            },
            {
                title: 'Sales report generated',
                subtitle: 'Last month sales report generated',
                type: 'light-success',
                icon: 'CheckIcon',
            },
            {
                title: 'High memory usage',
                subtitle: 'BLR Server using high memory',
                type: 'light-warning',
                icon: 'AlertTriangleIcon',
            },
        ]

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        return {
            notifications,
            systemNotifications,
            perfectScrollbarSettings,
        }
    },
}
</script>

<style>

</style>
