<template>
  <li
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
<!--      <span :class="`icomoon-`+item.icon"></span>-->
      <span class="menu-title text-truncate">{{i18nT(item.title) }}</span>
      <b-badge
        v-if="hasBadge()"
        pill
        variant="danger"
        class="ml-05 font-small-1"
      >
        {{ badgeCount }}
      </b-badge>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge> 
    </b-link>
  </li>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      badgeNames: ['Trash', 'Timesheets', 'Leave'],
      badgeName: ''
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)

    return {
      isActive,
      linkProps,
      updateIsActive,
    }
  },
  created() {
    this.badgeName = this.badgeNames.find(name => this.item.route.name && this.item.route.name.includes(name.toLowerCase()))
  },
  methods: {
    hasBadge() {
      return this.badgeName && this.badgeCount > 0
    }
  },
  computed: {
    badgeCount() {
      if( this.badgeName ) {
        
        return this.$store.getters[`app/current${this.badgeName}Count`]
      }
    }
  },

}
</script>
