import useAuth from '@/auth/useAuth';
import axios from '@axios';

export default async function () {
	const software = useAuth.getCurrentSoftware();

	return [
		{
			title: 'Employees',
			icon: 'UsersIcon',
			children: [
				{
					title: 'Employees',
					route: {
						name: 'employees',
						params: {software},
					},
				},
				{
					title: 'Teams',
					route: {
						name: 'employee-groups',
						params: {software},
					},
				},
			],
			module: 'employees',
		},
		{
			title: 'Onboarding',
			icon: 'LogInIcon',
			route: {path: `/on/onboarding/0`},
			module: 'onboarding',
		},
		{
			title: 'Offboarding',
			icon: 'LogOutIcon',
			route: {path: `/on/offboarding/0`},
			module: 'offboarding',
		},
	];
}
