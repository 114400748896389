import axios from '@axios';
import useAuth from '@/auth/useAuth';

export default async function () {

	let curWorkspace = useAuth.getCurWorkspace();

	//My Resume to be always available for candidates
	let menuEntries = [
		{
			title: 'My Resume',
			icon: 'FileTextIcon',
			route: {name: 'ca-resume'},
			module: 'always',
		},
	]

	//If candidate is in a workspace, show the workspace menu
	if (curWorkspace && curWorkspace.Id) {
		menuEntries.push(
			{
				title: 'Applications',
				icon: 'FileIcon',
				route: {
					name: 'ca-applications-redirect'
				},
				module: 'always',
			},
			{
				title: 'Tasks',
				icon: 'CheckCircleIcon',
				route: {
					name: 'tasks',
					params: {software: 'ca'},
				},
				module: 'always',
			},
			{
				title: 'Documents',
				icon: 'FolderIcon',
				route: {
					name: 'documents',
					params: {software: 'ca'},
				},
				module: 'always',
			},
		);
	}

	return menuEntries;

}
