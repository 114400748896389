import useAuth from '@/auth/useAuth';

export default function () {
	const software = useAuth.getCurrentSoftware();

	return [
		{
			title: 'Calendar',
			route: {
				name: 'calendar',
				params: {software}
			},
			icon: 'CalendarIcon',
			module: 'calendar'
		},
		{
			title: 'Tasks',
			icon: 'CheckCircleIcon',
			children: [
				{
					title: 'Tasks',
					route: {
						name: 'tasks',
						params: {software},
					},
				},
				{
					title: 'Task templates',
					route: {
						name: 'task-templates',
						params: {software},
					},
				},
				{
					title: 'Task groups',
					route: {
						name: 'task-groups',
						params: {software},
					},
				},
			],
			module: 'tasks'
		},
		{
			title: 'Documents',
			icon: 'FolderIcon',
			children: [
				{
					title: 'Documents',
					route: {
						name: 'documents',
						params: {software},
					},
				},
				{
					title: 'Document templates',
					route: {
						name: 'document-templates',
						params: {software},
					},
				},
				{
					title: 'Document groups',
					route: {
						name: 'document-groups',
						params: {software},
					},
				},
			],
			module: 'documents'
		},
		// {
		// 	title: 'Messages',
		// 	route: {
		// 		name: 'inbox',
		// 		params: {software},
		// 	},
		// 	icon: 'MessageSquareIcon',
		// 	module: 'always'
		// },
		{
			title: 'Organization profile',
			// route: 'company-profile',
			route: {
				name: 'company-profile',
				params: {software},
			},
			icon: 'LayoutIcon',
			module: 'company-profile',
		},
		{
			title: 'Settings',
			route: {
				name: 'settings',
				params: {software: software, tab: 'configuration'},
			},
			icon: 'SettingsIcon',
			module: 'always',
		},
		{
			title: 'Trash',
			route: {
				name: 'trash',
				params: {software},
			},
			icon: 'Trash2Icon',
			module: 'always'
		}
	];
}
