<template>
    <div
        class="main-menu menu-fixed menu-accordion menu-shadow"
        :class="[
              { 'expanded': !miniMenu },
              skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
            ]"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >
        <!-- main menu header-->
        <div class="navbar-header expanded">
            <slot
                name="header"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :toggleCollapsed="toggleCollapsed"
                :collapseTogglerIcon="collapseTogglerIcon"
            >
                <ul class="nav navbar-nav flex-row">

                    <!-- Logo & Text -->
                    <li class="nav-item mr-auto">
                        <b-link class="navbar-brand" to="/">
                            <span class="brand-logo">
                                <b-img
                                    v-if="miniMenu"
                                    :src="appCollapsedLogoImage"
                                    alt="skileo"
                                    class="logo-collapsed"
                                />
                                <b-img
                                    v-if="!miniMenu"
                                    :src="appLogoImage"
                                    alt="skileo"
                                />
                            </span>
                        </b-link>
                    </li>

                    <!-- Toggler Button -->
                    <li class="nav-item nav-toggle">
                        <b-link class="nav-link modern-nav-toggle">
                            <feather-icon
                                icon="XIcon"
                                size="20"
                                class="d-block d-xl-none"
                                @click="toggleVerticalMenuActive"
                            />
                            <feather-icon
                                :icon="collapseTogglerIconFeather"
                                size="20"
                                class="d-none d-xl-block collapse-toggle-icon"
                                @click="toggleCollapsed"
                            />
                        </b-link>
                    </li>
                </ul>
            </slot>
        </div>
        <!-- / main menu header-->
        <!-- Shadow -->
        <div
            :class="{'d-block': shallShadowBottom}"
            class="shadow-bottom"
        />

        <div
            class="transition-item softswitch"
            :class="{'collapsedSwitch': miniMenu}"
            v-if="curWorkspace && curWorkspace.WizzardCompleted"
        >
            <h4 class="transition-item" :class="{'invisble': miniMenu}">{{getTitle()}}</h4>
            <b-form-radio-group
                id="btn-radios-1"
                class="transition-item"
                :class="{'collapsedSofts': miniMenu}"
                button-variant="outline-primary"
                :options="softwares"
                buttons
                name="radios-btn-default"
                size="sm"
                v-model="currentSoftware"
                v-on:change="onChangeSoftware"
            />
        </div>
        <!-- main menu content-->
        <vue-perfect-scrollbar
            v-if="curWorkspace && curWorkspace.WizzardCompleted"
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
        >

            <vertical-nav-menu-items
                :items="menuItems"
                class="navigation navigation-main"
            />


            <!-- QUICK ADD CONTROLS -->
            <div class="row-fluid" v-if="userType !== 'Freelancer'">
                <div class="col-12 pl-2 pr-2 mt-2">
                    <button
                        v-if="miniMenu"
                        v-b-modal.modal-quick-add
                        class="btn btn-outline-secondary p-1"
                    >
                        <feather-icon icon="PlusCircleIcon" />
                    </button>
                    <button
                        v-else
                        v-b-modal.modal-quick-add
                        class="btn btn-outline-secondary btn-block"
                    >
                        <feather-icon icon="PlusCircleIcon" />
                        {{i18nT(`Quick add`)}}
                    </button>

                </div>

                <b-modal
                    id="modal-quick-add"
                    :title="i18nT(`Quick add`)"
                    hide-footer
                    no-close-on-backdrop
                >
                    <b-row>
                        <b-col md="4">
                            <a href="/re/job-offers/edit/new">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="BriefcaseIcon"
                                    :statistic="i18nT(`Job offer`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/re/create-application">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="UserIcon"
                                    :statistic="i18nT(`Candidate`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/hr/create-employee">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="UserIcon"
                                    :statistic="i18nT(`Employee`)"
                                />
                            </a>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <a :href="`/`+curSoftwareShort+`/calendar`">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="CalendarIcon"
                                    :statistic="i18nT(`Event`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/on/create-task">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="CheckCircleIcon"
                                    :statistic="i18nT(`Task`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/hr/create-document">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="FileTextIcon"
                                    :statistic="i18nT(`Document`)"
                                />
                            </a>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <a href="/re/inbox">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="MailIcon"
                                    :statistic="i18nT(`Message`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/hr/leave_absence">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="BellOffIcon"
                                    :statistic="i18nT(`Time off`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/hr/timesheets">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="ClockIcon"
                                    :statistic="i18nT(`Timesheet`)"
                                />
                            </a>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="4">
                            <a href="/en/surveys/edit/new">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="ClipboardIcon"
                                    :statistic="i18nT(`Survey`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/ac/invoices/create">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="FilePlusIcon"
                                    :statistic="i18nT(`Invoice`)"
                                />
                            </a>
                        </b-col>
                        <b-col md="4">
                            <a href="/ac/quotes/create">
                                <statistic-card-vertical
                                    color="primary"
                                    icon="BriefcaseIcon"
                                    :statistic="i18nT(`Quote`)"
                                />
                            </a>
                        </b-col>
                    </b-row>
                </b-modal>
            </div>
        </vue-perfect-scrollbar>
        <!-- /main menu content-->
    </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BFormRadioGroup, BRow, BCol } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import useAuth from '@/auth/useAuth'
import { BASE_URL } from '@core/utils/constants'
import _ from 'lodash'
import $store from '@/store'
import StatisticCardVertical from '@core/components/statistics-cards/StaticCardFA'
import AuthService from '@/@core/auth/authService'

export default {
    components: {
        VuePerfectScrollbar,
        VerticalNavMenuItems,
        BLink,
        BImg,
        BFormRadioGroup,
        StatisticCardVertical,
        BRow,
        BCol
    },
    props: {
        isVerticalMenuActive: {
            type: Boolean,
            required: true
        },
        toggleVerticalMenuActive: {
            type: Function,
            required: true
        }
    },
    created() {
        setTimeout(this.refreshSoftware, 1000)
        // this.loadCurrentSoftware()
        this.loadMenuItems()
    },
    data() {
        return {
            menuItems: [],
        }
    },
    computed: {
        miniMenu() {
            return !(!this.isVerticalMenuCollapsed || (this.isVerticalMenuCollapsed && this.isMouseHovered))
        },
        userType() {
            return useAuth.getUserType()
        },
        curWorkspace() {
                let storeWorkspace = this.$store.getters['app/currentWorkspace'];
                if(!storeWorkspace.Id){
                    storeWorkspace = useAuth.getCurWorkspace();
                }
                return storeWorkspace;
        },
        curSoftwareShort() {
            const softwares = ['en', 'hr', 'on', 're'];
            const urlSoftware = window.location.pathname.split('/')[1];
            if (softwares.includes(urlSoftware)) {
                return urlSoftware;
            }
            else {
                return '';
            }
        },
        storeSoftware(){
            return this.$store.getters['app/currentSoftware'];
        },
    },
    methods: {
        loadCurrentSoftware: function() {
            this.$http.get(BASE_URL + 'users/userInfo').then(({ data }) => {
                const curSoftware = _.values(useAuth.getSoftwares()).find(
                    software => software.id === data.data.user.CurrentSoftware
                )
                const curWorkspace = _.values(useAuth.getWorkspaces()).find(
                    software => software.Id === data.data.user.CurrentWorkspace
                )
                this.curSoftwareShort = curSoftware ? curSoftware.short : '';

                let needReload = false
                if (useAuth.getCurWorkspace().Id !== curWorkspace.Id) {
                    useAuth.setCurWorkspace(curWorkspace)
                    needReload = true
                }
                if (useAuth.getCurrentSoftwareId() !== curSoftware.id) {
                    useAuth.setCurrentSoftware(curSoftware.short)
                    $store.commit('app/SET_CURRENT_SOFTWARE', curSoftware.short)
                    needReload = true
                }

                if (needReload) {
                    this.$router.go()
                }
            })
        },
        onChangeSoftware: function(e) {
            const curSoftware = _.values(useAuth.getSoftwares()).find(
                software => software.short === e
            )
            this.$http
                .post(BASE_URL + 'main/switchSoftware', {
                    software_id: curSoftware.id
                })
                .then(() => {
                    useAuth.setCurrentSoftware(e)
                    this.$router
                    .replace(`/${e}/dashboard`) 
                    .then(() => {
                            console.log('function')
                            $store.commit('app/SET_CURRENT_SOFTWARE', e) 
                        });
                })
        },
        getTitle: function() {
            const software = useAuth.getCurrentSoftware()
            const titles = {
                re: this.i18nT(`Recruiting`),
                on: this.i18nT(`Onboarding`),
                hr: this.i18nT(`Human Resources`),
                en: this.i18nT(`Engagement`),
                ca: this.i18nT(`Candidate`),
                em: this.i18nT(`Employee`),
                ac: this.i18nT(`Accounting`),
            }
            return titles[software]
        },
        refreshSoftware: function() {
            this.currentSoftware = useAuth.getCurrentSoftware()
        },
        loadMenuItems: async function() {
            this.menuItems = []
            this.menuItems = await navMenuItems()
        }
    },
    mounted() {
        let userRole = useAuth.getUserType();
        if(userRole == 'Staff'){
            this.$http.post('users/publicLogin').then(({ data }) => {
                if(data.data.menu.user.Permissions) {
                    useAuth.setUserPermissions(data.data.menu.user.Permissions)
                }
            })
        }
    },
    setup(props) {
        const {
            isMouseHovered,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            updateMouseHovered
        } = useVerticalNavMenu(props)

        const { skin } = useAppConfig()

        // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
        const shallShadowBottom = ref(false)

        provide('isMouseHovered', isMouseHovered)

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false
        }

        const collapseTogglerIconFeather = computed(() =>
            collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
        )

        // App Name
        let preferredOrder = ['ac', 'hr', 're', 'on', 'en'];

        const { appName, appLogoImage, appCollapsedLogoImage } = $themeConfig.app;
        let softwares = _.values(useAuth.getSoftwares()).map(software => ({
            text: software.short.charAt(0).toUpperCase() + software.short.slice(1),
            value: software.short
        }));

        softwares.sort((a, b) => {
            let indexOfA = preferredOrder.indexOf(a.value);
            let indexOfB = preferredOrder.indexOf(b.value);

            // If not found in the preferredOrder, put it to the end
            if (indexOfA === -1) indexOfA = Number.MAX_VALUE;
            if (indexOfB === -1) indexOfB = Number.MAX_VALUE;

            return indexOfA - indexOfB;
        });


        // console.log(useAuth.getUserType())

        // if (useAuth.getUserType() === 'Freelancer') {
        //   softwares = softwares.filter(e => e.value !== 'em')
        // }

        const currentSoftware = useAuth.getCurrentSoftware()
        return {
            perfectScrollbarSettings,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            isMouseHovered,
            updateMouseHovered,
            collapseTogglerIconFeather,

            // Shadow Bottom
            shallShadowBottom,

            // Skin
            skin,

            // App Name
            appName,
            appLogoImage,
            appCollapsedLogoImage,
            softwares,
            currentSoftware
        }
    },
    watch: {
        storeSoftware(newVal) {
            if (newVal) {
                this.refreshSoftware();
                this.currentSoftware = newVal;
                this.loadMenuItems();
            }
        }
    }
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';

.logo-collapsed {
    width: 100%;
    max-width: 27px;
}

.invisble {
    opacity: 0;
}

.transition-item {
    transition: all 0.3s ease;
}

.softswitch {
    padding-left: 30px;
    margin-top: 32px;
    margin-bottom: 20px;
}

.collapsedSwitch {
    padding-left: 18px;
}

.collapsedSofts label:not(.active) {
    display: none;
}

#btn-radios-1 {
    .btn.active {
        color: white;
    }
    .btn:nth-child(1).active {
        background: #b748ff;
    }
    .btn:nth-child(2).active {
        background: #28c77a;
    }
    .btn:nth-child(3).active {
        background: #5a5aff;
    }
    .btn:nth-child(4).active {
        background: #26a7ff;
    }
    .btn:nth-child(5).active {
        background: #ff9f43;
    }
}
</style>
