<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- <b-avatar
            :src="currentWorkspaceLogo"
            class="mr-1 d-lg-none"
            :text="getInitials"
            ></b-avatar> -->

            <b-dropdown
                variant="link"
                toggle-class="p-0"
                :right="false"
                class="d-lg-none align-items-center"
            >
                <template #button-content>
                   <b-avatar
                        :src="currentWorkspaceLogo"
                        class="mr-1 d-lg-none"
                        :text="getInitials"
                    ></b-avatar>
                </template>
                
                <div class="pl-1 pr-1" v-for="(role, index) in roleOptions" :key="'Fields - ' + index">
                    <b-dropdown-item @click="onChangeWorkspace(role.value)"> {{ role.label }}</b-dropdown-item>
                </div>
                
            </b-dropdown>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <!-- Bookmarks Container -->
            <!-- <bookmarks /> -->

            <!-- WORKSPACE LOGO -->

            <b-avatar
            :src="currentWorkspaceLogo"
            class="mr-1"
            :text="getInitials"
            ></b-avatar>

            <!-- WORKSPACE SELECT -->
            
            <v-select
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
                style="width: 400px"
                v-model="currentWorkspace"
                @input="onChangeWorkspace"
            />

            <b-button
                v-if="userType !== 'Freelancer'"
                variant="primary"
                class="btn-icon rounded-circle ml-1"
                v-b-modal.modal-organization
            >
                <feather-icon icon="PlusIcon"/>
            </b-button>

            <b-button
                v-if="userType !== 'Freelancer'"
                variant="outline-primary"
                class="btn-icon rounded-circle ml-1"
                :to="{name: 'organizations'}"
            >
                <feather-icon icon="SettingsIcon" class="text-primary" />
            </b-button>

            <div v-if="false">
                <b-form-group class="mb-0">
                    <div
                        role="group"
                        class="input-group input-group-merge"
                    >
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <feather-icon icon="SearchIcon"/>
                            </div>
                        </div>
                        <b-form-input
                            v-model.lazy="searchQuery"
                            :placeholder="i18nT(`Search`)"
                            type="text"
                            class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div>
        </div>
        <b-navbar-nav class="nav align-items-center ml-auto">
            <!-- <locale /> -->
        </b-navbar-nav>
        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown class="mr-1" right
                v-if="availableRoles.length > 0">
                <template #button-content>
                    <feather-icon
                        icon="RefreshCwIcon"
                        size="21"
                        class="align-middle text-body"
                        v-b-tooltip="i18nT(`Switch current role`)"
                    />
                </template>
                <b-dropdown-item
                    v-for="role in availableRoles"
                    :key="'role-'+role.user_id"
                    link-class="d-flex align-items-center"
                    :disabled="role.user_id === 0"
                    @click="openConfirmModal(role)">
                    <feather-icon :icon="roleIcon(role)" class="mr-50"/>
                    <span>{{roleLabel(role) }}</span>
                </b-dropdown-item>


            </b-nav-item-dropdown>

            <search-bar v-if="false" class="mr-1"/>

            <b-link
                class="mr-1"
                @click="goToInbox">
                <feather-icon
                    :badge="allNotificationsCount"
                    badge-classes="bg-danger"
                    class="text-body"
                    icon="MailIcon"
                    size="21"
                />
            </b-link>

            <notification-dropdown v-if="false"></notification-dropdown>


            <b-nav-item-dropdown right>
                <template #button-content>
                    <feather-icon
                        icon="HelpCircleIcon"
                        size="21"
                        class="align-middle text-body"
                    />
                </template>
                <b-dropdown-item
                        :href="'https://hub.skileo.com/'"
                        class="d-flex align-items-center"
                        target="_blank"
                >
                        <feather-icon
                            icon="HelpCircleIcon"
                            size="16"
                            class="mr-50"
                        />
                        <span>{{ i18nT(`Support articles`) }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                    @click.prevent="openChat"
                    class="d-flex align-items-center"
                >

                        <feather-icon
                            icon="MessageSquareIcon"
                            size="16"
                            class="mr-50"
                        />
                        <span>{{ i18nT(`Live chat support`) }}</span>
                </b-dropdown-item>

            </b-nav-item-dropdown>


            <user-dropdown/>
        </b-navbar-nav>


        <b-modal
            id="modal-confirm-pass"
            :title="i18nT(`Confirm your identity`)"
            ok-only
            :ok-title="i18nT(`Submit`)"
            no-close-on-backdrop
            @ok="switchRole"
        >
            <p>
                {{ i18nT(`For improved account security, please enter your account password below to switch your current role.`) }}
            </p>

            <validation-observer ref="accountSwitchRules">

                <b-form-group
                    :label="i18nT(`Account password`)"
                    label-for="password"
                    class="required">
                    <validation-provider
                        #default="{ errors }"
                        :name="i18nT(`Account password`)"
                        rules="required"
                    >
                        <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid':null"
                        >
                            <b-form-input
                                v-model="confirmPassword"
                                :placeholder="i18nT(`Your password`)"
                                name="password"
                                :type="passwordFieldType"
                                :state="errors.length > 0 ? false : null"
                            />
                            <b-input-group-append is-text>
                                <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

            </validation-observer>

        </b-modal>




        <b-modal
            id="modal-organization"
            :title="i18nT(`Add new organization`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <p>
                {{ i18nT(`Create a new workspace for your Organizations, Companies and Local offices to this account. Each entity will have its own career site, recruitment and employee management space.`) }}
            </p>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Organization name`)"
                                label-for="orgname"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Organization Name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.orgName"
                                        :placeholder="i18nT(`Organization name`)"
                                        name="orgname"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    BLink,
    BNavbarNav,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BNavItemDropdown,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BCol,
    VBTooltip, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserDropdown from '../../navbar/UserDropdown.vue'
import useAuth from '@/auth/useAuth'
import {BASE_URL, API_KEY} from '@core/utils/constants'
import NotificationDropdown from './components/NotificationDropdown.vue'
// import Locale from './components/Locale.vue'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {localize} from 'vee-validate';
import store from "@/store";
import emailStoreModule from "@/views/apps/email/emailStoreModule";
import {onUnmounted} from "@vue/composition-api";
import axios from "axios";
import $store from "@/store";
import SearchBar from "@core/layouts/components/app-navbar/components/SearchBar.vue";

export default {
    components: {
      SearchBar,
        BInputGroup,
        BInputGroupAppend,
        BLink,
        BButton,
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BAvatar,
        BRow,
        BCol,
        BNavItemDropdown,
        BDropdown,
        BDropdownItem,
        // Navbar Components

        BNavbarNav,
        UserDropdown,
        NotificationDropdown,
        // Locale,
        vSelect,
        ValidationProvider,
        ValidationObserver
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {
            }
        }
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            allNotifications: [],
            allNotificationsCount: 0,
            roleOptions : [],
            availableRoles: [],
            targetRoleId: null,
            confirmPassword: '',
            currentWorkspace: '',
            required,
            itemForModal: {},
            passwordFieldType: 'password',
            currentWorkspaceLogo: ""
        }
    },
    computed: {
        getInitials() {

            const words = useAuth.getCurWorkspace().Label.trim().split(/\s+/);

            // Define a variable to hold the initials
            let initials = '';

            if (words.length > 2) {
                // If the first word is "The", skip it and take the initials from the next two words
                if (words[0].toLowerCase() === "the") {
                    initials = words[1][0] + words[2][0];
                } else {
                    // Otherwise, take the initials from the first two words
                    initials = words[0][0] + words[1][0];
                }
            } else if (words.length === 2) {
                // If there are exactly two words, take the first letter of each
                initials = words[0][0] + words[1][0];
            } else {
                // For a single word, take the first and second letters as a simple strategy
                // This part can be adjusted based on what is considered "meaningful"
                initials = words[0][0] + (words[0][1] || '');
            }

            // Return the initials in uppercase
            return initials.toUpperCase();
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        userType() {
            return useAuth.getUserType()
        },
        currentSoftware() {
            return this.$store.getters['app/currentSoftware']
        }
    },
    watch: {
        currentSoftware(val) {
            if(val === 'hr' || val === 'em') {
                this.fetchNewMessages()
            }
        }
    },
    created() {
        this.fetchAccounts();


        this.updateWorkspaces()
        this.currentWorkspace = useAuth.getCurWorkspace().Id
        this.currentWorkspaceLogo =  useAuth.getCurWorkspace().LogoUrl
        this.roleOptions = useAuth.getWorkspaces().map(workspace => {
            return {
                label: workspace.Label,
                value: workspace.Id
            }
        })

        let curUser = useAuth.getProfile();

        if(curUser){
            this.$crisp.push(["set", "user:nickname", [curUser.Label+" (U:"+curUser.Id+" / WS:"+useAuth.getCurWorkspace().Id+")"]]);
            this.$crisp.push(["set", "user:email", [curUser.Email]]);
            this.$crisp.push(["set", "user:company", [useAuth.getCurWorkspace().Label]]);
        }


        const userType = useAuth.getUserType()
        const url =
            userType === 'Freelancer' ? 'candidates/profile' : 'users/profile'
        this.$http.get(url).then(({data}) => {
            const {Language} = data.data
            //this.$i18n.locale = Language
            localStorage.setItem('locale', Language);
            this.changeLang(data.data.Language)

            import(`vee-validate/dist/locale/${Language}.json`).then(locale => {
                localize(Language, locale);
            });
        })
        this.$http.get('settings/timeformats').then(resp => {
            const timeformats = resp.data.data
            this.$http.get('users/profile').then(({data}) => {
                useAuth.setDateFormat(timeformats[data.data.Timeformat])
                useAuth.setDateFormatFlat(data.data.Timeformat)
                useAuth.setLocale(data.data.Language)
            })
        })
    },
    mounted() {
        this.fetchNewMessages();
        let msgRefreshIntervalId = setInterval(this.fetchNewMessages, 10000);
        $store.commit('app/SET_MSG_REFRESH_INTERVAL_ID', msgRefreshIntervalId);
    },
    setup() {
        const EMAIL_APP_STORE_MODULE_NAME = 'app-email'
        // Register module
        if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
        })
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType == 'password' ? 'text' : 'password';
        },
        openConfirmModal(role) {
            this.targetRoleId = role.user_id;
            this.$bvModal.show('modal-confirm-pass');
        },
        roleIcon(role) {
            switch (role.user_type) {
                case 'Freelancer':
                    return 'UserIcon'
                case 'Client':
                    return 'BriefcaseIcon'
                case 'Staff':
                    return 'UsersIcon'
            }
        },

        roleLabel(role) {
            switch (role.user_type) {
                case 'Freelancer':
                    return this.i18nT(`Employee`)
                case 'Client':
                    return this.i18nT(`Business owner`)
                case 'Staff':
                    return this.i18nT(`Company manager`)
            }
        },

        switchRole(event) {

            event.preventDefault()

            this.$refs.accountSwitchRules.validate().then(success => {

                if (success) {
                    let formData = new FormData;
                    formData.append('user_id', this.targetRoleId);
                    formData.append('password', this.confirmPassword);

                    this.$http.post('users/toggleAccount', formData).then(response => {

                        useAuth.setToken(response.data.data.token)
                        useAuth.setCurWorkspace(response.data.data.current_workspace)
                        useAuth.setWorkspaces(response.data.data.menu.workspaces)
                        useAuth.setSoftwares(response.data.data.menu.apps)
                        useAuth.setCurrentSoftware(response.data.data.current_sotftware)
                        useAuth.setProfile(response.data.data.menu.user)
                        useAuth.setUserType(response.data.data.user_type)
                        useAuth.setUserPermissions(response.data.data.menu.user.Permissions)

                        localStorage.setItem('userData', JSON.stringify(response.data.data.menu.user) )

                        $store.commit('app/UPDATE_LOCAL_USER', response.data.data.menu.user)
                        $store.commit('app/SET_CURRENT_SOFTWARE', response.data.data.current_sotftware)
                        $store.commit('app/SET_CURRENT_WORKSPACE', response.data.data.current_workspace)

                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(response),
                                icon: 'RefreshCwIcon',
                                variant: 'success',
                            },
                        })

                        window.location.href = '/';

                        //this.$router.push({name: 'dashboard'});
                    }).catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(error),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                    });
                }
            });

        },

        fetchAccounts() {
            this.$http.get('users/myAccounts').then(({data}) => {
                if(data.data.length > 0) {
                    this.availableRoles = data.data;

                    this.availableRoles.push({
                        user_id: 0,
                        user_type: useAuth.getUserType(),
                    })
                    this.availableRoles.concat(data.data);
                }

                console.log("Avaialble roles: ", this.availableRoles);
            })
        },

        openChat(){
            this.$crisp.do('chat:open')
        },
        goToInbox() {
            this.$router.push({name: 'inbox-all'});
        },
        fetchNewMessages() {

            axios.get(`${BASE_URL}main/counts`, {
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            })
            .then(({data}) => {

                this.allNotificationsCount = data.data.messagesCount;

                if (data.data.perMailbox) {
                    this.$store.commit('app/SET_MSG_MAILBOX_COUNT', data.data.perMailbox);
                }

                if (data.data.trashCount) {
                    this.$store.commit('app/SET_TRASH_COUNT', data.data.trashCount)
                }

                if (data.data.leavesCount) {
                    this.$store.commit('app/SET_LEAVE_COUNT', data.data.leavesCount)
                }

                if (data.data.timesheetsCount) {
                    this.$store.commit('app/SET_TIMESHEETS_COUNT', data.data.timesheetsCount)
                }
            });
        },
        updateWorkspaces: function () {
            try {
                this.$http.get('system/workspaces').then(({data}) => {
                    useAuth.setWorkspaces(data.data.workspaces)
                    this.roleOptions = data.data.workspaces.map(workspace => {
                        return {
                            label: workspace.Label,
                            value: workspace.Id
                        }
                    })
                })
            } catch (ex) {
                console.log(ex)
            }
        },
        onChangeWorkspace: function (e) {
            const workspaces = useAuth.getWorkspaces()
            this.$http
                .post(BASE_URL + 'main/switchCompany', {
                    company_id: e
                })
                .then(() => {
                    let workspace = workspaces.find(workspace => workspace.Id === e);
                    this.$store.dispatch('app/SET_CURRENT_WORKSPACE', workspace);
                    useAuth.setCurWorkspace(workspace);
                    this.$router.go();
                })
        },
        validationForm(event) {
            event.preventDefault()

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('Label', this.itemForModal.orgName)

                    this.$http
                        .post('companies', formData)
                        .then(({data}) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-organization')

                            this.$http
                                .post('main/switchCompany', {
                                    company_id: data.data.Id
                                })
                                .then(() => {
                                    useAuth.setCurWorkspace(data.data)
                                    window.location.replace('/re/company-profile/')
                                })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.rounded-circle.btn-outline-primary svg {
    opacity: 1;
}

</style>
